<template>
  <t-modal name="login" class="z-45">
    <form @submit.prevent class="p-2 mt-4" autocomplete="off">
      <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
      <input v-model="email" type="email" name="email" id="email" autocomplete="off" class="mt-1 bg-gray-50 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
      <label for="passw" class="mt-2 block text-sm font-medium text-gray-700">Senha</label>
      <div class="relative mt-1">
        <input v-model="passw" :type="spass ? 'text' : 'password'" name="passw" id="passw" autocomplete="off" class="bg-gray-50 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-300 hover:text-primary">
          <svg v-if="!spass" @click="spass = true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" /></svg>
          <svg v-else @click="spass = false" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" /><path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" /></svg>
        </div>
      </div>
      <div @click="forgotPass()" class="flex items-center justify-end my-4">
        <p class="font-medium text-sm text-primary hover:text-indigo-500">Esqueceu sua senha?</p>
      </div>
      <button @click="loginEmail()" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">Entrar</button>
      <hr class="my-5 border-gray-300 w-full">
      <button @click="loginSocial()" type="button" class="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
        <div class="flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="w-6 h-6" viewBox="0 0 48 48"><defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/><path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/><path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/><path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/></svg>
          <span class="ml-4 text-gray-900">Entrar com Google</span>
        </div>
      </button>
      <p class="mt-6 text-center">Não possui conta? <span @click="$modal.hide('login'), $modal.show('register')" class="text-primary hover:text-primarydark font-semibold">Cadastre-se</span></p>
    </form>
  </t-modal>
</template>

<script>
import { auth, GoogleProvider } from '@/firebase'

export default {
  data() {
    return {
      email: '',
      passw: '',
      spass: false,
    }
  },
  methods: {
    async loginEmail() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await auth.signInWithEmailAndPassword(this.email, this.passw)
      .then(result => {
        if (auth.currentUser) {
          this.$modal.hide('login')
          this.$toast.success('Sucesso! Seja bem-vindo')
          this.$store.dispatch('fetchUserProfile', result.user) 
        }
      })
      .catch((err) => { 
        const errors = {'auth/invalid-email': 'e-mail inválido', 'auth/user-disabled': 'conta desativada', 'auth/user-not-found': 'conta não localizada', 'auth/wrong-password': 'senha incorreta' }
        this.$toast.error('Erro, ' + errors[err.code])
      })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async loginSocial() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await auth.signInWithRedirect(GoogleProvider)
    },

    async forgotPass() {
      if (!this.email) { return this.$toast.error('Preencha seu email para recuperar a senha') }

      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await auth.sendPasswordResetEmail(this.email)
      .then(() => this.$toast.success('Email com link de recuperação enviado'))
      .catch((err) => { 
        const errors = {'auth/invalid-email': 'e-mail inválido', 'auth/user-disabled': 'conta desativada', 'auth/user-not-found': 'conta não localizada' }
        this.$toast.error('Erro, ' + errors[err.code] || 'tente novamente')
      })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    }
  }
}
</script>